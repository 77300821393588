const sentences = [
    '<Developer skills = { \n  "css", "html ", "react "\n}/>',
    'isResponsive\n ? return " :) " \n : return "Meh"',
    '<Person \n isEnthusiastic = { true } \n/>',
    'recommendedBooks = [ \n "Clean Code", \n "Don\'t Make Me Think" ]',
    'hobbies = [ \n "board games", "gym" \n]',
    'favoriteFoods = [ \n "crêpes", "pasta", "pizza" \n]',
    '<Developer \n isTeamPlayer = { true } \n/>',
    'isMobileFirst\n ? return "You rock :)" \n : return ":( "',
    'languages = [ \n "spanish", "catalan", \n "english" ]',
    'frameworks = [ \n "React ", "Angular" \n]',
]

export default sentences