export const menuItems = [
    {
        link: 'hero',
        text: 'Home'
    },
    {
        link: 'skills',
        text: 'My Skills'
    },
    {
        link: 'contact',
        text: 'Contact Me'
    },
]