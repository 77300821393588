import { useState } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import { animateScroll as scroll } from 'react-scroll'
import { menuItems } from '../../utilities/menuItems'
import './header.css'

function Header() {

    const [logoTheme, setLogoTheme] = useState("light")
    const [toggleBtnTheme, setToggleBtnTheme] = useState("dark")
    const [menuOpened, setMenuOpened] = useState(false)
    const toggleMenuOpened = () => setMenuOpened(!menuOpened)

    const handleLogoClick = () => {
        scroll.scrollToTop()
        setMenuOpened(false)
    }

    const handleSetActiveLink = (to) => {
        switch(to) {
            case "hero":
                setLogoTheme("light")
                setToggleBtnTheme("dark")
                break;

            case "skills":
                setLogoTheme("dark")
                setToggleBtnTheme("light")
                break;

            case "contact":
                setLogoTheme("light")
                setToggleBtnTheme("light")
                break;
        }
    }

    return (
        <header className={ menuOpened ? "site-header menu-opened" : "site-header" }>
            <LinkRouter to="/" onClick={ handleLogoClick } className={ logoTheme === "light" ? "logo logo-light" : "logo logo-dark" } aria-label="Logo, go to homepage">
                <h1 className="logo__text">
                    <span className="logo__accent">&lt;</span>ac/<span className="logo__accent">&gt;</span>
                </h1>
            </LinkRouter>

            <button className={ toggleBtnTheme === "light" ? "menu-toggle-light btn menu-toggle" : "menu-toggle-dark btn menu-toggle"} type="button" aria-label="Open menu" onClick={ toggleMenuOpened }>
                <svg className="menu-toggle__bar" width="30" height="5" viewBox="0 0 30 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75018 1.41579C2.75054 1.4745 3.91206 1.50038 5.23476 1.49343C6.56048 1.50973 7.98776 1.48915 9.5166 1.43171C11.0485 1.3975 12.6369 1.3441 14.282 1.27151C15.93 1.22216 17.565 1.1627 19.1868 1.09313C20.8318 1.02054 22.4187 0.95552 23.9476 0.898073C25.5027 0.860837 26.9431 0.850367 28.2688 0.866664C28.6881 1.0011 28.968 1.1537 29.1085 1.32449C29.2519 1.51851 29.3449 1.77821 29.3873 2.10358C29.4691 2.73108 29.1962 3.08573 28.5687 3.16752C28.1292 3.05935 27.4036 3.02392 26.3922 3.06121C25.4039 3.09547 24.1949 3.1585 22.7651 3.25031C21.3616 3.36234 19.8054 3.48243 18.0967 3.61059C16.388 3.73876 14.6167 3.83963 12.7827 3.91322C10.9749 4.00701 9.14803 4.04422 7.30192 4.02484C5.47904 4.00243 3.72843 3.8997 2.05007 3.71665C1.7823 3.657 1.53122 3.45337 1.29683 3.10575C1.06546 2.78137 0.934635 2.50297 0.904344 2.27056C0.867994 1.99167 0.935211 1.78201 1.10599 1.64157C1.30304 1.52134 1.51777 1.44608 1.75018 1.41579Z" fill="#F0B666"/>
                </svg>
                <svg className="menu-toggle__bar" width="30" height="5" viewBox="0 0 30 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75018 1.41579C2.75054 1.4745 3.91206 1.50038 5.23476 1.49343C6.56048 1.50973 7.98776 1.48915 9.5166 1.43171C11.0485 1.3975 12.6369 1.3441 14.282 1.27151C15.93 1.22216 17.565 1.1627 19.1868 1.09313C20.8318 1.02054 22.4187 0.95552 23.9476 0.898073C25.5027 0.860837 26.9431 0.850367 28.2688 0.866664C28.6881 1.0011 28.968 1.1537 29.1085 1.32449C29.2519 1.51851 29.3449 1.77821 29.3873 2.10358C29.4691 2.73108 29.1962 3.08573 28.5687 3.16752C28.1292 3.05935 27.4036 3.02392 26.3922 3.06121C25.4039 3.09547 24.1949 3.1585 22.7651 3.25031C21.3616 3.36234 19.8054 3.48243 18.0967 3.61059C16.388 3.73876 14.6167 3.83963 12.7827 3.91322C10.9749 4.00701 9.14803 4.04422 7.30192 4.02484C5.47904 4.00243 3.72843 3.8997 2.05007 3.71665C1.7823 3.657 1.53122 3.45337 1.29683 3.10575C1.06546 2.78137 0.934635 2.50297 0.904344 2.27056C0.867994 1.99167 0.935211 1.78201 1.10599 1.64157C1.30304 1.52134 1.51777 1.44608 1.75018 1.41579Z" fill="#F0B666"/>
                </svg>
                <svg className="menu-toggle__bar" width="30" height="5" viewBox="0 0 30 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75018 1.41579C2.75054 1.4745 3.91206 1.50038 5.23476 1.49343C6.56048 1.50973 7.98776 1.48915 9.5166 1.43171C11.0485 1.3975 12.6369 1.3441 14.282 1.27151C15.93 1.22216 17.565 1.1627 19.1868 1.09313C20.8318 1.02054 22.4187 0.95552 23.9476 0.898073C25.5027 0.860837 26.9431 0.850367 28.2688 0.866664C28.6881 1.0011 28.968 1.1537 29.1085 1.32449C29.2519 1.51851 29.3449 1.77821 29.3873 2.10358C29.4691 2.73108 29.1962 3.08573 28.5687 3.16752C28.1292 3.05935 27.4036 3.02392 26.3922 3.06121C25.4039 3.09547 24.1949 3.1585 22.7651 3.25031C21.3616 3.36234 19.8054 3.48243 18.0967 3.61059C16.388 3.73876 14.6167 3.83963 12.7827 3.91322C10.9749 4.00701 9.14803 4.04422 7.30192 4.02484C5.47904 4.00243 3.72843 3.8997 2.05007 3.71665C1.7823 3.657 1.53122 3.45337 1.29683 3.10575C1.06546 2.78137 0.934635 2.50297 0.904344 2.27056C0.867994 1.99167 0.935211 1.78201 1.10599 1.64157C1.30304 1.52134 1.51777 1.44608 1.75018 1.41579Z" fill="#F0B666"/>
                </svg>
            </button>

            <nav className="site-menu">
                <ul className="site-menu__list">
                    {
                        menuItems.map((item, index) => (
                            <li className="site-menu__list-item" key={ index }>
                                <LinkScroll 
                                    to={ item.link } 
                                    smooth={ true }
                                    duration={ 800 }
                                    delay={ 150 }
                                    spy={ true }
                                    offset={ -80 }
                                    className="site-menu__link"
                                    activeClass="active-link"
                                    onClick={ toggleMenuOpened }
                                    onSetActive={ handleSetActiveLink }
                                >
                                    <svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.5877 20.4636C23.7199 19.6701 23.9559 18.9184 24.2957 18.2084C24.6544 17.4984 25.0697 16.8302 25.5417 16.2038C26.0325 15.5774 26.5611 15.0136 27.1275 14.5124C27.6939 14.0113 28.2508 13.5728 28.7983 13.1969C29.3458 12.7793 29.865 12.4452 30.3558 12.1946C30.8655 11.9023 31.2903 11.6935 31.6301 11.5682C31.6301 11.5264 31.6301 11.4638 31.6301 11.3802C31.649 11.255 31.6584 11.1714 31.6584 11.1297C31.6584 10.5868 31.5169 10.1482 31.2337 9.81415C30.9505 9.48005 30.7239 9.25035 30.554 9.12507C30.3086 8.91625 29.8744 8.58216 29.2514 8.12277C28.6284 7.66338 27.9582 7.18311 27.2408 6.68197C26.5423 6.13905 25.8815 5.6379 25.2585 5.17852C24.6355 4.71913 24.2013 4.40591 23.9559 4.23886C23.6538 3.40362 23.5122 2.69366 23.5311 2.10898C23.5688 1.48254 23.701 1.00228 23.9275 0.668177C24.1541 0.334078 24.4373 0.125265 24.7771 0.0417403C25.1358 -0.0417844 25.485 -2.10964e-05 25.8249 0.167028C26.1836 0.292314 26.4951 0.56377 26.7594 0.981396C27.0237 1.39902 27.1936 1.94193 27.2691 2.61013L33.839 8.24806C34.613 8.95802 35 10.0438 35 11.5055C35 12.1737 34.8962 12.6749 34.6885 13.009C34.4997 13.3431 34.2637 13.5728 33.9806 13.6981C33.6974 13.8233 33.4047 13.9069 33.1027 13.9486C32.8195 13.9486 32.5835 13.9486 32.3947 13.9486L24.6921 21.2779C24.6544 21.2779 24.6166 21.2988 24.5789 21.3406C24.56 21.3406 24.5317 21.3406 24.4939 21.3406C24.324 21.3406 24.1635 21.2779 24.0125 21.1526C23.8803 20.9856 23.7388 20.7559 23.5877 20.4636Z" fill="#F0B666"/>
                                        <path d="M33.1603 10.37L2 10.6912" stroke="#F0B666" strokeWidth="4" strokeLinecap="round"/>
                                    </svg>
                                    { item.text }
                                </LinkScroll>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </header>
    )
}

export default Header